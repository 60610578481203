let mockData = {
    "sceneTitle": "telestration mock",
    // "showPath": "http://touch-prod/imsget/imsget?path=IMS_IMAGES%5cHD%5cLOGOS%5cSPORTS_MEDIA%5cSHOW%5cNBA_THEJUMP_2019.PNG",
    // "showPath": "http://touch-prod/imsget/imsget?path=IMS_IMAGES%5cHD%5cLOGOS%5cBASEBALL%5cMLB%5c2018_HOMERUNDERBY_VERTICAL.PNG",
    "showPath": "",
    "showX": "0",
    "showY": "0",
    "showScale": ".3",
    // "sponsorPath": "http://touch-prod/imsget/imsget?path=IMS_IMAGES%5cHD%5cLOGOS%5cOTHER%5cMISCELLANEOUS%5cTHEJUMP_JUMPRECOMMENDS.PNG",
    "sponsorPath": "",
    "sponsorX": "0",
    "sponsorY": "0",
    "sponsorScale": ".8",
    "clipData": [
      {
        "clipPosition": 1,
        "clipName": "",
        // "clipName": "76ers_steal.mov",
        // "clipName": "lamar_TD.mov",
        "clipFolder": "",
        "playOnOpen": "N"
      },
      // {
      //   "clipPosition": 1,
      //   // "clipName": "lamar_TD.mov",
      //   "clipName": "lamar_TD2.mov",
      //   "clipFolder": "",
      //   "playOnOpen": "N"
      // },
      // {
      //   "clipPosition": 1,
      //   "clipName": "lamar_TD2.mov",
      //   "clipFolder": "",
      //   "playOnOpen": "N"
      // },
      // {
      //   "clipPosition": 1,
      //   // "clipName": "chiefs_sack.mov",
      //   "clipName": "bg_nfl.jpg",
      //   "clipFolder": "",
      //   "playOnOpen": "N"
      // }
    ],
    "clearOnPlay": "Y",
    "scrubBarPos": "BOTTOM",
    "useiPadLayout": "Y",
    "controlsPosition": "RIGHT",
    "flip180": "N",
    "verticalPalettes": "N",
    "showEmojis": "Y",
    "slowMotion": "Y",
    "drawWhileClosed": "N",
    "ballIconSize": "SMALL",
    "paletteTools": [
      {
        "toolType": "SEQNUMBER",
        "toolColor": "",
        "isDefault": "N",
      },
      {
        "toolType": "UNDOALL",
        "toolColor": "",
        "isDefault": "N"
      },
      {
        "toolType": "COLOR",
        "toolColor": "Blue",
        "isDefault": "N"
      },
      {
        "toolType": "COLOR",
        "toolColor": "Red",
        "isDefault": "Y"
      },
       {
        "toolType": "DOUBLEBLOCK",
        "toolColor": "",
        "isDefault": "N",
      },
      {
        "toolType": "MAGNIFY",
        "toolColor": "",
        "isDefault": "N",
        "canvasLayer": "base"
      },
      {
        "toolType": "FLASHLIGHT",
        "toolColor": "",
        "isDefault": "N",
      },
      {
        "toolType": "CONE",
        "toolColor": "",
        "isDefault": "N",
      },
      {
        "toolType": "DRAWARROW",
        "toolColor": "",
        "isDefault": "N",
      },
      {
        "toolType": "DRAW",
        "toolColor": "",
        "isDefault": "Y",
      },
      
      // {
      //   "toolType": "ARROW",
      //   "toolColor": "",
      //   "isDefault": "N",
      // },
      // {
      //   "toolType": "LINEBASKETBALLS",
      //   "toolColor": "",
      //   "isDefault": "N",
      // },
      // {
      //   "toolType": "CIRCLESOLID",
      //   "toolColor": "",
      //   "isDefault": "N",
      // },
      // {
      //   "toolType": "CIRCLESPIN",
      //   "toolColor": "",
      //   "isDefault": "Y",
      // },
      // {
      //   "toolType": "MARKERX",
      //   "toolColor": "",
      //   "isDefault": "N",
      // },
      // {
      //   "toolType": "MARKERO",
      //   "toolColor": "",
      //   "isDefault": "Y",
      // },
      // {
      //   "toolType": "CONE",
      //   "toolColor": "",
      //   "isDefault": "N",
      // },
      // {
      //   "toolType": "BLOCK",
      //   "toolColor": "",
      //   "isDefault": "N",
      // },
      // {
      //   "toolType": "RECT",
      //   "toolColor": "",
      //   "isDefault": "N",
      // },
      
      // {
      //   "toolType": "COLOR",
      //   // "toolColor": "bisque",
      //   "toolColor": "Green",
      //   "isDefault": "N"
      // },
      // {
      //   "toolType": "COLOR",
      //   "toolColor": "Yellow",
      //   "isDefault": "Y"
      // },
      // {
      //   "toolType": "COLOR",
      //   "toolColor": "Orange",
      //   // "toolColor": "#08ff3d",
      //   "isDefault": "N"
      // },
      // {
      //   "toolType": "COLOR",
      //   // "toolColor": "fuchsia",
      //   "toolColor": "White",
      //   // "toolColor": "rgb(213, 30, 212)",
      //   // "toolColor": "#d51ed4",
      //   "isDefault": "N"
      // },
      // {
      //   "toolType": "UNDO",
      //   "toolColor": "",
      //   "isDefault": "N"
      // },
    ],
    "emojiTools": [
      {
        "chipType": "EMOJI",
        "filePath": "https://d1vm5rbk0pxhab.cloudfront.net/emojis/grinning_face.png",
        "iconPath": "",
        "isDefault": "Y",
        "imageScale": 60
      },
      {
        "chipType": "EMOJI",
        "filePath": "https://d1vm5rbk0pxhab.cloudfront.net/emojis/face_with_tears_of_joy.png",
        "iconPath": "",
        "isDefault": "N",
        "imageScale": 60
      },
      {
        "chipType": "EMOJI",
        "filePath": "https://d1vm5rbk0pxhab.cloudfront.net/emojis/face_screaming_in_fear.png",
        "iconPath": "",
        "isDefault": "N",
        "imageScale": 60
      },
      {
        "chipType": "EMOJI",
        "filePath": "https://d1vm5rbk0pxhab.cloudfront.net/emojis/loudly_crying_face.png",
        "iconPath": "",
        "isDefault": "N",
        "imageScale": 60
      },
      {
        "chipType": "EMOJI",
        "filePath": "https://d1vm5rbk0pxhab.cloudfront.net/emojis/pouting_face.png",
        "iconPath": "",
        "isDefault": "N",
        "imageScale": 60
      },
      {
        "chipType": "EMOJI",
        "filePath": "https://d1vm5rbk0pxhab.cloudfront.net/emojis/face_with_open_mouth.png",
        "iconPath": "",
        "isDefault": "N",
        "imageScale": 60
      },
      {
        "chipType": "EMOJI",
        "filePath": "https://d1vm5rbk0pxhab.cloudfront.net/emojis/grimacing_face.png",
        "iconPath": "",
        "isDefault": "N",
        "imageScale": 60
      },
      {
        "chipType": "EMOJI",
        "filePath": "https://d1vm5rbk0pxhab.cloudfront.net/emojis/raising_hands.png",
        "iconPath": "",
        "isDefault": "N",
        "imageScale": 60
      },
      {
        "chipType": "EMOJI",
        "filePath": "https://d1vm5rbk0pxhab.cloudfront.net/emojis/thumbs_up.png",
        "iconPath": "",
        "isDefault": "N",
        "imageScale": 60
      },
      {
        "chipType": "EMOJI",
        "filePath": "https://d1vm5rbk0pxhab.cloudfront.net/emojis/flexed_biceps.png",
        "iconPath": "",
        "isDefault": "N",
        "imageScale": 60
      }
    ]
  }

export default mockData;