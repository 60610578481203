// const validate = require("./validate");
import validate from './validate';

// uses https://solo.myid.disney.com/ for myId process
const logIn = redirectUri => {
  // console.log(redirectUri)
  let windowPathName = window.location.pathname
  let windowOrigin = window.location.origin;
  let windowHostName = window.location.hostname;
  let MYID_CLIENT = "TouchProd";
  // Need myid.html to live on root directory
  let MYID_REDIRECT = windowOrigin + "/myid.html";
  // used in myid.html for window redirect back to correct app if logging in with MyID
  // ex: window.location = '/web-tele-aws'
  sessionStorage.redirectUri = windowPathName;
  if (windowHostName === "localhost") {
    MYID_CLIENT = "TouchUILocal";
    sessionStorage.redirectUri = '';
  }
  if (redirectUri) {
    sessionStorage.redirectUri = decodeURIComponent("/" + redirectUri);
  }
  window.location = `https://idp.myid.disney.com/as/authorization.oauth2?client_id=${MYID_CLIENT}&response_type=id_token%20token&redirect_uri=${MYID_REDIRECT}&nonce=APPLICATION_GENERATED_ONE_TIME_NONCE&scope=openid%20profile`;
}

const load = cb => {
  // skips the entire MyID login part if cookie and jwt token are valid (just loads the current app) 
  // otherwise redirect to MyID to log in
  if (!isLoggedIn()) {
    // let uriParts = parseLocation();
    // if (uriParts.queryParams && uriParts.queryParams.ref) {
    //   return logIn(uriParts.queryParams.ref[0]);
    // }
    // else { return logIn(); }
    return logIn();
  } else {
    if (sessionStorage.redirectUri) {
      window.location.href = sessionStorage.redirectUri;
    }
    sessionStorage.clear();
    // check if jwt cookie is valid
    let token = getCookie("jwt");
    validate(token, () => logIn());
  }
  if (cb){ cb(); }
}

const getCookie = cname => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") { c = c.substring(1); }
    if (c.indexOf(name) === 0) { return c.substring(name.length, c.length); }
  }
  return "";
}
// creates MyID cookie
const createMyIdCookie = (user, jwt) => {
  // note: we can keep the cookie active longer than the 5 minute expiration because all myid lambda protected assets
  // are loaded on first page load--thereafter, we can use the socketID as a semi-auth key when communicating with the API layer
  // allow cookie to persist for 7 days
  const expires = new Date(new Date().getTime() + 60*60*24*7*1000).toUTCString();

  // set the user info in window for global access/debugging
  const email = (user.preferred_username || "").toLowerCase();
  window.userData = user;
  window.userData.preferred_username = email;
  // Build the set-cookie string:
  const cookieString = `jwt=${jwt}; path=/; expires=${expires}`;
  // console.log("myid cookie update", cookieString);
  // Create or update the cookie:
  document.cookie = cookieString;
}
const isLoggedIn = () => {
  let token = getCookie("token");
  if (token) return true; // note: in deployed environment, Lambda gateway will block invalid token
  let jwt = getCookie("jwt");
  if (!jwt) return false;
  let payloadEncoded = jwt.split(".")[1];
  payloadEncoded = payloadEncoded.replace(/[-]/g, "+");
  payloadEncoded = payloadEncoded.replace(/[_]/g, "/");

  // User MyID info (user name, email, etc.)
  let payloadString = atob(payloadEncoded);
  // NOTE: Logs for MyID info
  // console.log(payloadString);
  const user = JSON.parse(payloadString);
  // create MyID cookie (update expiration)
  createMyIdCookie(user, jwt);
  return true;
}

// const parseLocation = () => {
//   // console.log('window href', window.location.href);
//   let frontBack = window.location.href.split("?", 2);
//   let front = frontBack[0];
//   let queryString = null;
//   if (frontBack.length > 1) { queryString = frontBack[1]; }
//   let pathHash = front.split("#", 2);
//   let pathPart = pathHash[0];
//   let hashPart = null;
//   if (pathHash.length > 1) { hashPart = pathHash[1]; }
//   let path = pathPart.split("/").slice(3).join("/");
//   let queryParams = null;
//   if (queryString) {
//     queryParams = {};
//     let items = queryString.split("&");
//     for (let x = 0; x < items.length; x++) {
//       let item = items[x];
//       let pair = item.split("=", 2);
//       let name = pair[0];
//       let value = pair.length > 1 ? pair[1] : null;
//       let values = queryParams[name] ? queryParams[name] : [];
//       if (value) { values.push(value); }
//       queryParams[name] = values;
//     }
//   }

//   let hashParams = null;
//   if (hashPart) {
//     hashParams = {};
//     let items = hashPart.split("&");
//     let pair;
//     for (let x = 0; x < items.length; x++) {
//       pair = items[x].split("=", 2);
//       hashParams[pair[0]] = pair[1] ? pair[1] : null;
//     }
//   }
//   let result = {
//     url: window.location.href,
//     path: "/" + path,
//     queryString: queryString,
//     queryParams: queryParams,
//     hashParams: hashParams
//   };
//   return result;
// }

export default load;
