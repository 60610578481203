import { createContext } from 'react';
import { decorate, observable } from 'mobx';
import { sharedDataStore, DataStore } from '@pe-touch/ui/stores/dataStore';
import { rootTeleStore } from '@pe-touch/ui/stores/teleStore/rootTeleStore';

let store_data = sharedDataStore._currentValue;
let root_data = rootTeleStore._currentValue;

export class appDataStore extends DataStore {

  data_vcs_store = store_data.data_vcs;
  sharedAutoScroll = store_data.autoScroll;
  data_videos_selected = {
    curVideoUploadedName: '',
    curBlobUrl: '',
    fileUploaded: false
  }  

  updateData_app = (newData) => {

    // creating universal data obj for all tele apps
    let data_vcs_fe = root_data.teleStore.createTeleFeCntData(this.data_vcs, newData, newData.clipData);

    // pass app with data from custom front end obj
    return this.data_vcs = {...data_vcs_fe};
  }
}

decorate(appDataStore, {
  data_videos_selected: observable
})

export const dataStore = createContext(new appDataStore());
