import React, { useRef, useContext, useEffect } from 'react';
import { dataStore } from '../../stores/dataStore';
import { sharedDataStore } from '@pe-touch/ui/stores/dataStore';
import { rootTeleStore } from '@pe-touch/ui/stores/teleStore/rootTeleStore';
import { observer } from 'mobx-react-lite';
import _isEmpty  from 'lodash/isEmpty';
import { clearTelestration } from '@pe-touch/ui/components/Tele/TeleCanvas/functions/teleEvents';
import t from 'styled-themer';
import styled from 'styled-components';
// import { txt_h_styles_xl } from '@pe-touch/ui/styles/js/styles/typography';
import { imgCancel, imgReset } from '@pe-touch/ui/styles/js/styles/images';
import { contentAbsolute } from '@pe-touch/ui/styles/js/global/vars_global_js';
import { gpl } from '@pe-touch/ui/functions/utils';
import { spacing_md, spacing_sm, spacing_xl } from '@pe-touch/ui/styles/js/styles/spacing';
import './ButtonsResetApp.scss';

const spl = {
  localProps: {
    Spnr_showImg: '', 
    Spnr_showX: '',
    Spnr_showY: '',
    Spnr_showScale: '',
    Spnr_brandImg: '', 
    Spnr_brandX: '',
    Spnr_brandY: '',
    Spnr_brandScale: '',
    circleSizeValue: '', 
    circleRadius: '',
    opacityValue: ''
  }
};

const headTitle_bg = t("header", "headTitle_bg");
// const headTitle_txtClr = t("header", "headTitle_txtClr");

const BtnResetContainer = styled.div`
  &.btn-scroll-container {
    .half-circle-container {
      position: absolute;
      width: calc(${gpl(spl,'circleSizeValue')} / 2);
      height: calc(${gpl(spl,'circleSizeValue')} / 2);
      z-index: 1000;
      &.left { 
        left: 0; 
        .app > & { left: 0; } 
      }
      &.right { 
        right: 0;
        .app > & { right: 0; } 
      }
      &.up, &.down {
        .app:not(.show-row-labels) & {
          left: ${spacing_md};
        }
        .col-2:not(.show-row-labels) & {
          left: calc(${spacing_sm} + ${spacing_xl});
        }
      }
      &.up { top: 0; }
      &.down { bottom: 0; }
      &.down { 
        .app:not(.show-row-labels) & {
          bottom: calc(1.5vh + .6vh);
        }
      }
      &.up {
        .app:not(.show-row-labels):not(.show-col-labels) & {
          top: 1vh;
        }
      }
      .half-circle-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .btn--half-circle {
      position: absolute;
      width: ${gpl(spl,'circleSizeValue')};
      height: ${gpl(spl,'circleSizeValue')};
      top: calc(-${gpl(spl,'circleSizeValue')} / 2);
      z-index: 3;
      line-height: calc(${gpl(spl,'circleSizeValue')} / 2);
      background: #8e8e8e;
      opacity: ${gpl(spl,'opacityValue')};
      border-radius: ${gpl(spl,'circleRadius')};
    }
    .half-circle-container:not(.right) .btn--half-circle {
      left: calc(-${gpl(spl,'circleSizeValue')} / 2);
    }
    .half-circle-container.right .btn--half-circle {
      right: calc(-${gpl(spl,'circleSizeValue')} / 2);
    }
    .half-circle-container.down .btn--half-circle {
      top: 0;
    }
    
    .groups__slots-container & {
      width: 100%;
      left: 0;
      top: 0;
      height: calc(${gpl(spl,'circleSizeValue')} / 2);
    }
  }
`;

const HeaderBar = styled.div`
  &.title-bar {
    background-size: 100% 100%;
    margin: 0 auto;
    min-width: 20%;
    max-width: 85%;
    ${contentAbsolute(false,'left','top')};
    height: 11vh;
    max-width: none;
    &.one-logo {
      .title-text-container {
        max-width: 85%;
      }
    }
    &.no-logo {
      .title-text-container {
        max-width: 100%;
        width: 100%;
      }
    }
    &.sm {
      .btn--cancel { left: -2vw; }
      .btn--reset { right: -2vw; }
    }
    .logo-container {
      &:before {
        ${contentAbsolute(true,'right','top')};
        width: .5vw;
        background: linear-gradient(to left, rgba(0, 0, 0, 0.2) 0%, transparent 70%);
      }
      &.logo--brand-container {
        overflow: hidden;
        &:before {
          right: auto;
          left: 0;
          background: linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, transparent 70%);
        }
      }
    }
    .title-text-container {
      width: auto;
      max-width: 80%;    
      background: ${headTitle_bg};
      padding: .7vw .8vw .7vw 1.3vw;
      .title-text {
        width: 100%;
        pointer-events: none;
      }
    }
    .logo-container {
      position: relative;
      width: 11.2vw;
      background: rgba(128, 128, 128, 0.5);
      .sponsor-container {
        width: 100%;
      }
    }
    .btn--reset {
      background-image: ${imgReset};
      right: 50%;
    }
    .btn--cancel {
      background-image: ${imgCancel};
      left: 50%;
    }
  }
`;

// const HeaderText = styled.div`
//   &.title-text {
//     ${txt_h_styles_xl};
//     color: ${headTitle_txtClr};
//   }
// `;


// const SponsorShowLogo = styled.div.attrs(() => ({
//     style: {
//       transform: `translate(${gpla(spl,'Spnr_showX')}, ${gpla(spl,'Spnr_showY')}) scale(${gpla(spl,'Spnr_showScale')})`
//     },
//   }))`
//   position: absolute;
//   left: 0;
//   top: 0;  
//   width: 30vh;
//   height: 100%;
//   z-index: 200;
//   background: url(${gpl(spl,'Spnr_showImg')}) center/contain no-repeat;
// `;
// const SponsorBrandLogo = styled.div.attrs(() => ({
//     style: {
//       transform: `translate(${gpla(spl,'Spnr_brandX')}, ${gpla(spl,'Spnr_brandY')}) scale(${gpla(spl,'Spnr_brandScale')})`
//     },
//   }))`
//   position: absolute;
//   right: 0;
//   top: 0;  
//   width: 30vh;
//   height: 100%;
//   z-index: 200;
//   background: url(${gpl(spl,'Spnr_brandImg')}) center/contain no-repeat;
// `;


const ButtonsResetApp = observer(() => {

  const store_data = useContext(dataStore);
  const store_data_shared = useContext(sharedDataStore);
  const { videoStore } = useContext(rootTeleStore);

  useEffect(() => {
    if (!_isEmpty(store_data_shared.headerDataShow)) {
      store_data.data_vcs.showX = store_data_shared.headerDataShow.showX;
      store_data.data_vcs.showY = store_data_shared.headerDataShow.showY;
      store_data.data_vcs.showScale = store_data_shared.headerDataShow.showScale;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[store_data_shared.headerDataShow]);

  useEffect(() => {
    if (!_isEmpty(store_data_shared.headerDataSponsor)) {
      store_data.data_vcs.sponsorX = store_data_shared.headerDataSponsor.sponsorX;
      store_data.data_vcs.sponsorY = store_data_shared.headerDataSponsor.sponsorY;
      store_data.data_vcs.sponsorScale = store_data_shared.headerDataSponsor.sponsorScale;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[store_data_shared.headerDataSponsor]);

  const calcSponsorTransform = (value) => {
    if (value || value === 0) {
      if (value[0] === '.') {
        value = -(value * 100);
      }
      if (value[2] === '.' || value[1] === '.') {
        let decimalValue = value.split('.');
        value = -(decimalValue[1] * 10);
      }
      return `${value}%`;
    }
  }

  // Sponsor Show Logo
  const Spnr_showX = calcSponsorTransform(store_data.data_vcs.showX);
  const Spnr_showY = calcSponsorTransform(store_data.data_vcs.showY);
  const Spnr_showScale = 1.6 + Number(store_data.data_vcs.showScale);

  // Sponsor Branded logo
  const Spnr_brandX = calcSponsorTransform(store_data.data_vcs.sponsorX);
  const Spnr_brandY = calcSponsorTransform(store_data.data_vcs.sponsorY);
  const Spnr_brandScale = 1.2 + Number(store_data.data_vcs.sponsorScale);


  spl.localProps = {
    Spnr_showImg:  store_data.data_vcs.showPath, 
    Spnr_showX: Spnr_showX,
    Spnr_showY: Spnr_showY,
    Spnr_showScale: Spnr_showScale,
    Spnr_brandImg:store_data.data_vcs.sponsorPath, 
    Spnr_brandX: Spnr_brandX,
    Spnr_brandY: Spnr_brandY,
    Spnr_brandScale: Spnr_brandScale,
    circleSizeValue: '15vh', 
    circleRadius: '50%',
    opacityValue: '.3'
  };

  const btn_reset = useRef(null), btn_cancel = useRef(null);
  // const sponsorShow = store_data.data_vcs.showPath !== "" ? true : false;
  // const sponsorBrand = store_data.data_vcs.sponsorPath !== "" ? true : false;
  const sceneTitle = store_data.data_vcs.sceneTitle;
  const headerClass = store_data.data_vcs.showPath && store_data.data_vcs.sponsorPath ? '' 
    : store_data.data_vcs.showPath || store_data.data_vcs.sponsorPath
    ? sceneTitle && sceneTitle.length < 12 ? 'one-logo sm' : 'one-logo' 
    : sceneTitle && sceneTitle.length < 16 ? 'no-logo sm' :'no-logo';
  
  const updateTitleBar = () => {
    btn_reset.current.setAttribute("style", "visibility: visible;");
    btn_cancel.current.setAttribute("style", "visibility: visible;");
  }
  const updateReset = e => {
    e.stopPropagation();
    btn_reset.current.setAttribute("style", "visibility: hidden;");
    btn_cancel.current.setAttribute("style", "visibility: hidden;");
    setTimeout(() => {
      
      let curVidControls = document.querySelector('.video-controls');
      // pre actions
      clearTelestration();
      // stop loading if videos are loading
      store_data.loading = false;
      // pause if video is playing
      if (videoStore.data_video.isPlaying) {
        let pausedBtn = curVidControls.querySelector('.pause');
        if (pausedBtn) { pausedBtn.click(); }
        videoStore.data_video.isPlaying = false;
      }
      // close palettes that are opened
      let paletteBtnsSelected = document.querySelectorAll('.palette-btn-wrapper .btn--tele.selected');
      paletteBtnsSelected.forEach(el => el.click());

      // reset if video ended
      if (videoStore.data_video.videoEnded) {
        let resetBtn = curVidControls.querySelector('.reset');
        if (resetBtn) { resetBtn.click(); }
        videoStore.data_video.videoEnded = false;
      }

      // // update preview ref time to current tele video time 
      // let curVidPlaying = findCurrentObj({obj: videoStore.blobUrls, key: 'url', value: videoStore.videoRef.current.currentSrc});
      // videoStore.videoRefArrTime[curVidPlaying.curIndex].videoTime = parseInt(videoStore.data_video.scrubPosition) / videoStore.data_video.frameRate;
      
      // // update preview image to correct time
      // let previewVids = document.querySelectorAll('.vid-previews-container video');
      // initializeVideo(previewVids[curVidPlaying.curIndex], curVidPlaying.curIndex);
      
      // // after tele video fades out
      // setTimeout(() => {
      //   // reset if video ended
      //   if (videoStore.data_video.videoEnded) {
      //     let resetBtn = curVidControls.querySelector('.reset')
      //     if (resetBtn) { resetBtn.click(); }
      //   }
      //   // show preview images
      //   document.querySelector('.app').classList.remove('hide-previews');
      //   document.querySelector('.tele-wrapper').classList.add('hidden');
      //   videoStore.showPreviews = true;
      // }, 700);

      videoStore.showVideos = false;
      store_data.updateData_app(store_data.data_vcs);
    },50);
  }
  const updateCancel = e => {
    e.stopPropagation();
    btn_reset.current.setAttribute("style", "visibility: hidden;");
    btn_cancel.current.setAttribute("style", "visibility: hidden;");
  }

  let timeout;
  let lastTap = 0;

  const checkDoubleClick = e => {
    let currentTime = new Date().getTime();
    let tapLength = currentTime - lastTap;
    clearTimeout(timeout);
    // if double tap
    if (tapLength < 300 && tapLength > 0) {
      updateTitleBar(); 
      e.preventDefault();
    } else {
      timeout = setTimeout(() => {
        clearTimeout(timeout);
      }, 300);
    }
    lastTap = currentTime;
  }

  return (
    <div className="app__header container justify-center items-center">
      <div className="header-wrapper container items-center relative">
        <BtnResetContainer className="groups__scroll-container btn-scroll-container">
          <div className="half-circle-container left" onClick={e => checkDoubleClick(e)}>
          <div className="half-circle-wrapper">
            <div className="btn--half-circle"/>
          </div>
          </div>
          <div className="half-circle-container right" onClick={e => checkDoubleClick(e)}>
            <div className="half-circle-wrapper">
              <div className="btn--half-circle"/>
            </div>
          </div>
        </BtnResetContainer>
        <HeaderBar className={`title-bar relative flex ${headerClass}`} onClick={updateTitleBar}>
          {/* { sponsorShow && 
            <div className="logo-container logo--show-container">
              <SponsorShowLogo className="sponsor-container"/> 
            </div>
          }
          <div className="title-text-container">
            <HeaderText className="title-text text-center uppercase">
              <span className="resize">{store_data.data_vcs.sceneTitle}</span>
            </HeaderText>
          </div>
          { sponsorBrand && 
            <div className="logo-container logo--brand-container">
              <SponsorBrandLogo className="sponsor-container"/>
            </div>
          } */}
          <div className="btn--reset" onClick={updateReset} ref={btn_reset}></div>
          <div className="btn--cancel" onClick={updateCancel} ref={btn_cancel}></div>
        </HeaderBar>
      </div>
    </div>
  );
});     

export default ButtonsResetApp;
