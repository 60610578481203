import { vars_nfl } from "@pe-touch/ui/styles/js/themes/nfl/nfl";
import { vars_main } from "@pe-touch/ui/styles/js/themes/main/main";

// create a theme obj of current themes needed for build
// can override default theme vars
let themes_vars = {
  "main" : {
    ...vars_main,
    "slotBg_size": "22vw 7vw",
    "misc": {
      ...vars_main.misc,
    },
  },
  "nfl" : {...vars_nfl},
};

export default themes_vars;