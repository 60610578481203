import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { observer } from 'mobx-react-lite';
import { dataStore } from '../../stores/dataStore';
import { rootTeleStore } from '@pe-touch/ui/stores/teleStore/rootTeleStore';
import { sharedDataStore } from '@pe-touch/ui/stores/dataStore';
import mockData from '../../data/mock-data';
import paletteData from '../../data/palette-data';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { S3Client, ListObjectsCommand } from '@aws-sdk/client-s3';
import Grid from '@pe-touch/ui/components/Grid/Grid';
import { loadVideosFromClient } from '@pe-touch/ui/components/Tele/TeleVideo/functions/videoUtils';
import styled from 'styled-components';
import { spacing_lg, spacing_md, spacing_sm } from '@pe-touch/ui/styles/js/styles/spacing';
import './SelectVideo.scss';

const SelectVideoStyle = styled(Grid)`
  & {
    padding: ${spacing_lg};
    > div {
      width: 100%;
      padding: ${spacing_lg} 0;
      p {
        display: inline;
      }
    }
    .select-label, button {
      padding: ${spacing_sm};

    }
    .select-label {
      margin-right: ${spacing_md};
    }
    button, .file-select-btn {
      cursor: pointer;
      &:disabled {
        cursor: not-allowed;
      }
    }
    button { margin-top: ${spacing_md}; }
    .file-clear-btn {
      margin-top: 1.4vw;
      background-color: #f18b8b;
      border: none;
    }
    .upload-file-txt {
      color: white;
      margin-left: .6vw;
    }
  }
`;

const SelectVideo = observer(props => {

  const store_data = useContext(dataStore);
  const store_data_shared = useContext(sharedDataStore);
  const { videoStore } = useContext(rootTeleStore);

  const [selectListArr, setSelectListArr] = useState([]);
  const [curVideoSelected, setCurVideoSelected] = useState('');
  const [curTalSelected, setCurTalSelected] = useState('');

  const uploadFileRef = React.useRef();
  const awsFileSelRef = React.useRef();
  const talentSelRef = React.useRef();

  const cookies = new Cookies();
  let cookie_videoSelected = 'videoSelected';
  let cookie_talSelected = 'talSelected';

  // Initialize the Amazon Cognito credentials provider
  const REGION = "us-east-1";
  const BUCKET_NAME = "cspe-media-files";
  const FOLDER_NAME = 'Clips/';
  const POOL_ID = "us-east-1:289d6c31-4fbb-4d15-a39b-f409c3aa1909";

  const s3 = new S3Client({
    region: REGION,
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: REGION }),
      identityPoolId: POOL_ID,
    }),
  });

  const getSelectList = async () => {
    let selectListArr = [];
    try {
      const videoUrlObjs = await s3.send(new ListObjectsCommand({ Delimiter: "", Bucket: BUCKET_NAME, Prefix: FOLDER_NAME }));
      if (videoUrlObjs.Contents) {
        videoUrlObjs.Contents.map(videoUrlObj => {
          // just get path names cleanup to just get file name
          let videoUrlPath = videoUrlObj.Key;
          let videoName = decodeURIComponent(videoUrlPath.replace(FOLDER_NAME, ""));
          selectListArr.push(videoName);
          return videoUrlObj
        });
        // NOTE: istObjectsCommand.Contents is returning root folder (Ex: Clips/) 
        // We don't want that result so just remove it for now 
        selectListArr.shift();
      } 
      else {
        setCurVideoSelected('');
      }
    } catch (err) {
      return console.error("There was an error listing your file: " + err.message);
    }
    return selectListArr;
  };

  const changeVideoSelectedInput = e => {
    setCurVideoSelected(e.currentTarget.value);
    store_data.data_videos_selected.curVideoUploadedName = '';
    store_data.data_videos_selected.fileUploaded = false;
    uploadFileRef.current.value = '';
  }

  const changeVideoFileInput = e => {
    store_data.data_videos_selected.curVideoUploadedName = e.currentTarget.files[0].name;
  }

  const removeFileUploaded = e => {
    store_data.data_videos_selected.curVideoUploadedName = '';
    store_data.data_videos_selected.fileUploaded = false;
    uploadFileRef.current.value = '';
    // reset data_vcs so it reload the selected clip
    store_data.data_vcs.cntData[0].cntName = '';
  }

  const triggerFileUpload = () => {
    if (uploadFileRef.current) {
      uploadFileRef.current.click();
    }
  }

  const loadTele = () => {
    videoStore.showVideos = true;
    store_data.loading = true;
    store_data_shared.loading = true;

    // update store_data.shared.data_vcs with talent data
    let teleTalSelected = talentSelRef.current.value;    
    setCookieTal(teleTalSelected);
    setPaletteOptions(teleTalSelected);

    // update video to what's selected
    const uploadedVid = uploadFileRef.current.files[0];
    if (uploadedVid) {
      const videoURL = URL.createObjectURL(uploadedVid);
      videoStore.data_video.selectedVideo = videoURL;
      videoStore.blobUrls = [videoURL];
      store_data.loading = false;
      store_data_shared.loading = false;
      store_data.data_videos_selected.fileUploaded = true;
    }
    else if (store_data.data_videos_selected.fileUploaded) {}
    else {
      let teleVidSelected = awsFileSelRef.current.value;

      // if new video is different from last time create a new blob Url and load it
      if (store_data.data_vcs.cntData && store_data.data_vcs.cntData[0].cntName !== teleVidSelected) {

        let updMockData = {...mockData};
        updMockData.clipData[0].clipName = teleVidSelected;
        (async() => {
          await store_data_shared.updateData(updMockData.clipData);
          let store_data_sharedCloned = {...store_data_shared};
          store_data_sharedCloned.data_vcs.cntData[0].cntName = teleVidSelected;
          loadVideosFromClient(store_data_sharedCloned.data_vcs.cntData);
          store_data.data_videos_selected.fileUploaded = false;
        })();       
      }
      // NOTE: need this without doing getData in App.js
      // else {
      //   // first time run 
      //   let updMockData = {...mockData};
      //   updMockData.clipData[0].clipName = teleVidSelected;

      //   store_data_shared.updateData(updMockData);
      //   let cntData = [{
      //     clipPosition: 1,
      //     cntFolder: "",
      //     cntName: teleVidSelected,
      //     cntSource: "VIDEO",
      //     playOnOpen: "N",
      //   }];

      //   loadVideosFromClient(cntData);
      // }
      setCookie(teleVidSelected);
    }
  }

  const setPaletteOptions = talentSelected => {
    let paletteDataSelected = paletteData[talentSelected];
    store_data.data_vcs = {...store_data.data_vcs, paletteTools: paletteDataSelected.paletteTools, emojiTools: paletteDataSelected.emojiTools};
    store_data_shared.updateData(store_data.data_vcs);
  }

  const getCookieVideoSelected = () => cookies.get(cookie_videoSelected); 
  const setCookie = cookieValue => {
    if (cookieValue && cookieValue !== getCookieVideoSelected()) {
      cookies.set(cookie_videoSelected, cookieValue, { path: '/' });
    }
  }
  const getCookieTalSelected = () => cookies.get(cookie_talSelected); 
  const setCookieTal = cookieValue => {
    if (cookieValue && cookieValue !== getCookieTalSelected()) {
      cookies.set(cookie_talSelected, cookieValue, { path: '/' });
    }
  }

  // load videos from server onLoad
  useEffect(() => {
    // init set cookie
    setCurVideoSelected(getCookieVideoSelected());
    setCurTalSelected(getCookieTalSelected());
    // set video url path to aws path
    videoStore.data_video.clipPathRoot = 'https://d1vm5rbk0pxhab.cloudfront.net';
    let isMounted = true;
    getSelectList().then(data => {
      if (isMounted) { 
        setSelectListArr(data);
        // if videos in AWS folder and no cookie for saved video, set cookie to be first video in list
        let cookieVid = getCookieVideoSelected();
        if (data.length > 0 && !cookieVid) { setCurVideoSelected(data[0]); }
       }
    })
    return () => { isMounted = false };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <SelectVideoStyle container className="select-video-container">
      <div>
        <p className="select-label">AWS Clip To Load:</p>
        {selectListArr.length > 0 
          ? <select className="tele-vid-selector" value={curVideoSelected} ref={awsFileSelRef} onChange={(e) => changeVideoSelectedInput(e)}>
            {selectListArr.map((videoPath,i) => <option key={i}>{videoPath}</option>)}
          </select>
          : <p style={{"color": "red", "textTransform": "uppercase"}}>** no clips on aws server</p>
          // : <select className="tele-vid-selector" value={curVideoSelected} ref={awsFileSelRef} onChange={(e) => changeVideoSelectedInput(e)}>
          //   <option value="NFL_NYG_SEA.mov">NFL_NYG_SEA.mov</option>
          //   <option value="lamar_TD.mov">lamar_TD.mov</option>
          //   <option value="pass1.mov">pass1.mov</option> 
          //   <option value="broken.mov">broken.mov</option> 
          // </select>
        }
      </div>
      <div>
        <p className="select-label">Upload A Clip Instead:</p>
        <input type="file" accept="video/*" id="video-input" style={{display: "none"}} ref={uploadFileRef} onChange={(e) => changeVideoFileInput(e)} />
        <input type="button" className="file-select-btn" value="Choose File" onClick={triggerFileUpload} />
        <p className="upload-file-txt">{store_data.data_videos_selected.curVideoUploadedName}</p>
        <br/><button className="file-clear-btn" onClick={removeFileUploaded}>Remove Uploaded Clip</button>
      </div>
      <div>
        <p className="select-label">Talent Palette:</p>
        <select className="tele-tal-selector" ref={talentSelRef} value={curTalSelected} onChange={(e) => setCurTalSelected(e.currentTarget.value)}>
          {paletteData && Object.keys(paletteData).map(k=> <option key={k} value={k}>{k}</option>)}
        </select>
      </div>
      <button onClick={loadTele} disabled={!curVideoSelected || curVideoSelected === ''}>Load Clip</button>
    </SelectVideoStyle>
  );
});

export default SelectVideo;

