import React, { useContext } from 'react';
// import { dataStore } from '../../stores/dataStore';
import { rootTeleStore } from '@pe-touch/ui/stores/teleStore/rootTeleStore';
import Grid from '@pe-touch/ui/components/Grid/Grid';
import SelectVideo from '../SelectVideo/SelectVideo';
import ButtonsResetApp from '../ButtonsResetApp/ButtonsResetApp';
import Tele from '@pe-touch/ui/components/Tele/Tele';
// import { loadVideosFromClient } from '@pe-touch/ui/components/Tele/TeleVideo/functions/videoUtils';
import './Body.scss';

let resetCountBody = 0;

const Body = () => {

  // creates new instance on every playout, reset, theme change
  resetCountBody++;

  // const store_data = useContext(dataStore);
  const { videoStore } = useContext(rootTeleStore);


  // useEffect(() => {
  //   store_data.data_vcs.cntData && loadVideosFromClient(store_data.data_vcs.cntData);
  // },[store_data.data_vcs.cntData]);

  return (
    <Grid container className="app__body" key={resetCountBody}>
      <div className="body-wrapper">
        <div className="body-content">
          { videoStore.showVideos ? <React.Fragment><ButtonsResetApp /><Tele displayLogos /></React.Fragment> : <SelectVideo /> }
        </div>
      </div>
    </Grid>
  );
}

export default Body;
