// Web Font Loader - https://www.npmjs.com/package/webfontloader
// NOTE: not working in IE - may need to use something else if need support
import WebFont from 'webfontloader';

WebFont.load({
  custom: {
    families: [
      'DrescherGroteskBT-Demi', 'HarmoniaSans-Bold',
      'Druk-Bold', 'DrukText-Bold', 'DrukWide-Bold', 'shapiro-85superheavywide', 
      'BentonSans-Bold'
    ]
  }
});