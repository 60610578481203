import React, { useContext, useEffect, useCallback, useRef } from 'react';
import classnames from 'classnames';
import global_vars from '@pe-touch/ui/styles/js/global/vars_global.json';
import themes_vars from './styles/js/themes/_theme_vars';
import { dataStore } from './stores/dataStore';
import mockData from './data/mock-data';
// import mockData2 from './data/mock-data2';
import { rootTeleStore } from '@pe-touch/ui/stores/teleStore/rootTeleStore';
import { sharedDataStore } from '@pe-touch/ui/stores/dataStore';
import Body from './components/Body/Body.js';
import { observer } from 'mobx-react-lite';
import { initVCSBridge } from "@pe-touch/ui/functions/proxy/bridge/vcsBridge";
import { onVCSBridgeOpen, onVCSBridgeMessage, onVCSBridgeClose, onVCSBridgeError, flipYPosValues } from '@pe-touch/ui/functions/proxy/proxyCommands';
import styled, { ThemeProvider } from 'styled-components';
import { txt_h_family_md } from '@pe-touch/ui/styles/js/styles/typography';
import { color_white } from '@pe-touch/ui/styles/js/styles/palette.js';
import { bgImage } from '@pe-touch/ui/styles/js/styles/mics.js';
import './App.scss';

const AppWrapper = styled.div`
  &.app {
    background: ${bgImage};
    background-size: cover;
    ${txt_h_family_md};
    color: ${color_white};
    // .btn--tele {
    //   display: block !important;
    // }
    // .loaded-vid {
    //   background: black;
    //   object-position: bottom;
    //   object-fit: contain;
    // }
  }
`;

const App = observer(() => {

  const store_data = useContext(dataStore);
  const store_data_shared = useContext(sharedDataStore);
  const { teleStore } = useContext(rootTeleStore);

  const theme = 'main';
  const themeOptions = themes_vars;

  let resizeTimer;
  let currentWindowWidth = window.innerWidth;
  let currentWindowHeight = window.innerHeight;
  let initLoad = useRef(true);
  let contentLoad = useRef(false);
  let timeoutDur = 100;

  const className = classnames(
    'app',
    'tele',
    'remove-textHighlight',
    'hide-children'
  );

  const revealApp = useCallback(() => {
    if (!store_data_shared.loading && store_data.data_vcs.cntData && store_data.data_vcs.cntData.length > 0) {
      if (contentLoad.current) {
        store_data.squishTextHrz('.resize',true);
        document.querySelector('.app').classList.remove('hide-children');
        contentLoad.current = false;
      }
    }
  },[store_data, store_data_shared.loading]);

  const postDOMRenders = useCallback(() => {
    // store_data.setLabelMaxHeight('.body-header .label--col', px_vh, 'colLabelMaxHeight');
    setTimeout(() => { revealApp(); },timeoutDur);
  }, [revealApp, timeoutDur]);

  const initRender = useCallback(() => {
    setTimeout(() => {
      // first time loading
      if (initLoad.current) {
        if(!document.querySelector('.app')) {
          setTimeout(() => { initRender(); },timeoutDur);
        } else {
          document.querySelector('.app').classList.remove('hide-children');
          initLoad.current = false;
        }
      }
      else {
        // if everything is populated, resize all labels and show
        if (!store_data_shared.loading && store_data.data_vcs.cntData && store_data.data_vcs.cntData.length > 0) {
          // all content has loaded, do post DOM updates 
          if (!contentLoad.current) {
            contentLoad.current = true;
            postDOMRenders();
          }
          // retry post renders
          else {
            contentLoad.current = false;
            setTimeout(() => { initRender(); },timeoutDur);
          }       
        }
        else {
          // theme switch on initial load
          if (!store_data.data_vcs.categories) {
            document.querySelector('.app').classList.remove('hide-children');
          }
          // DOM not finished populating data, 
          // recursive function until all content loads
          else {
            setTimeout(() => { initRender(); },timeoutDur);
          }
        }
      }
    }, 50);
  }, [store_data, initLoad, postDOMRenders, timeoutDur, store_data_shared.loading]); 
  
  const screenResizeUpdates = () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      if (window.innerWidth !== currentWindowWidth || window.innerHeight !== currentWindowHeight) {
        // resize text to fit new screen dimensions
        store_data.squishTextHrz('.resize', true);
        currentWindowWidth = window.innerWidth;
        currentWindowHeight = window.innerHeight;
        
        // telestration updates
        teleStore.setElRefDimensions();
      }
    },500);
  } 

  useEffect(() => {
    // makes sure fonts are preloaded for scaling text
    require('./fonts/font');

    // typical bridge parameters
    // these will use defaults for preview and proxy sockets
    // verbose debug will be turned off
    let bridgeParams = {
      onOpen: onVCSBridgeOpen,
      onMessage: onVCSBridgeMessage,
      onClose: onVCSBridgeClose,
      onError: onVCSBridgeError
    };

    console.log("Connecting to VCS...")
    initVCSBridge(bridgeParams);

    // setup text scale on screen resize
    window.addEventListener("resize", screenResizeUpdates);
    return () => {
      window.removeEventListener("resize", screenResizeUpdates);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store_data]);

  // update app data with vcs data when played out
  useEffect(() => {
    store_data.updateData_app(store_data_shared.data_vcs); 
  },[store_data, store_data_shared.data_vcs]);

  useEffect(() => {
    // preform DOM updates after app loads
    initRender();
  }, [initRender, store_data.data_vcs, theme, store_data_shared.loading]);

  const getData = () => {
    let yFlipMockData = flipYPosValues(mockData);
    // store_data.updateData_app(yFlipMockData);
    store_data_shared.updateData(yFlipMockData);
    store_data.setupAppStyles();
  }
  // const getData2 = () => {
  //   store_data_shared.updateData(mockData2);
  //   store_data.setupAppStyles();
  // }

  useEffect(getData, []);
  useEffect(() => {return},[store_data.loading]);

  return (
    <ThemeProvider theme={{ ...global_vars, ...themeOptions[theme] }}>
      <AppWrapper className={`${className} ${theme}`}>
        {/* <button className="btn-data" onClick={getData} style={{right: "9%"}}> get data </button>
        <button className="btn-data" onClick={getData2}> get multi data </button> */}
        <Body />
      </AppWrapper>
    </ThemeProvider>
  );
})

export default App;
